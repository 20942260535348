// Animations (from mdl http://www.getmdl.io/)

@mixin material-animation-fast-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-fast-out-slow-in;
}

@mixin material-animation-linear-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-linear-out-slow-in;
}

@mixin material-animation-fast-out-linear-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-fast-out-linear-in;
}

@mixin material-animation-default($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-default;
}

@mixin rotate-180() {
    -webkit-transform: rotate( 180deg );
    -moz-transform: rotate( 180deg );
    -o-transform: rotate( 180deg );
    -ms-transform: rotate(180deg);
    transform: rotate( 180deg );
}

// Nvabar burger animations (on close)

@mixin bar-animation($type){
     -webkit-animation: $type 500ms linear 0s;
     -moz-animation: $type 500ms linear 0s;
     animation: $type 500ms 0s;
     -webkit-animation-fill-mode: forwards;
     -moz-animation-fill-mode: forwards;
     animation-fill-mode: forwards;
}

@mixin topbar-x-rotation(){
    @keyframes topbar-x {
      0% {top: 0px; transform: rotate(0deg); }
      45% {top: 6px; transform: rotate(145deg); }
      75% {transform: rotate(130deg); }
      100% {transform: rotate(135deg); }
    }
    @-webkit-keyframes topbar-x {
      0% {top: 0px; -webkit-transform: rotate(0deg); }
      45% {top: 6px; -webkit-transform: rotate(145deg); }
      75% {-webkit-transform: rotate(130deg); }
      100% { -webkit-transform: rotate(135deg); }
    }
    @-moz-keyframes topbar-x {
      0% {top: 0px; -moz-transform: rotate(0deg); }
      45% {top: 6px; -moz-transform: rotate(145deg); }
      75% {-moz-transform: rotate(130deg); }
      100% { -moz-transform: rotate(135deg); }
    }
}

@mixin topbar-back-rotation(){
    @keyframes topbar-back {
      0% { top: 6px; transform: rotate(135deg); }
      45% { transform: rotate(-10deg); }
      75% { transform: rotate(5deg); }
      100% { top: 0px; transform: rotate(0); }
    }

    @-webkit-keyframes topbar-back {
      0% { top: 6px; -webkit-transform: rotate(135deg); }
      45% { -webkit-transform: rotate(-10deg); }
      75% { -webkit-transform: rotate(5deg); }
      100% { top: 0px; -webkit-transform: rotate(0); }
    }

    @-moz-keyframes topbar-back {
      0% { top: 6px; -moz-transform: rotate(135deg); }
      45% { -moz-transform: rotate(-10deg); }
      75% { -moz-transform: rotate(5deg); }
      100% { top: 0px; -moz-transform: rotate(0); }
    }
}

@mixin bottombar-x-rotation(){
    @keyframes bottombar-x {
      0% {bottom: 0px; transform: rotate(0deg);}
      45% {bottom: 6px; transform: rotate(-145deg);}
      75% {transform: rotate(-130deg);}
      100% {transform: rotate(-135deg);}
    }
    @-webkit-keyframes bottombar-x {
      0% {bottom: 0px; -webkit-transform: rotate(0deg);}
      45% {bottom: 6px; -webkit-transform: rotate(-145deg);}
      75% {-webkit-transform: rotate(-130deg);}
      100% {-webkit-transform: rotate(-135deg);}
    }
    @-moz-keyframes bottombar-x {
      0% {bottom: 0px; -moz-transform: rotate(0deg);}
      45% {bottom: 6px; -moz-transform: rotate(-145deg);}
      75% {-moz-transform: rotate(-130deg);}
      100% {-moz-transform: rotate(-135deg);}
    }
}

@mixin bottombar-back-rotation{
    @keyframes bottombar-back {
      0% { bottom: 6px;transform: rotate(-135deg);}
      45% { transform: rotate(10deg);}
      75% { transform: rotate(-5deg);}
      100% { bottom: 0px;transform: rotate(0);}
    }
    @-webkit-keyframes bottombar-back {
      0% {bottom: 6px;-webkit-transform: rotate(-135deg);}
      45% {-webkit-transform: rotate(10deg);}
      75% {-webkit-transform: rotate(-5deg);}
      100% {bottom: 0px;-webkit-transform: rotate(0);}
    }
    @-moz-keyframes bottombar-back {
      0% {bottom: 6px;-moz-transform: rotate(-135deg);}
      45% {-moz-transform: rotate(10deg);}
      75% {-moz-transform: rotate(-5deg);}
      100% {bottom: 0px;-moz-transform: rotate(0);}
    }

}


@mixin transform-scale($value){
     -webkit-transform: scale($value);
        -moz-transform: scale($value);
        -o-transform: scale($value);
        -ms-transform: scale($value);
        transform: scale($value);
}

@mixin rotateY-180() {
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    -ms-transform: rotateY(180deg);
    transform: rotateY( 180deg );
}

@mixin transitions($time, $type){
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

@mixin transitions-property($property, $time, $type){
    -webkit-transition: $property $time $type;
    -moz-transition: $property $time $type;
    -o-transition: $property $time $type;
    -ms-transition: $property $time $type;
    transition: $property $time $type;
}

@mixin transform-translate-x($value){
     -webkit-transform:  translate3d($value, 0, 0);
        -moz-transform: translate3d($value, 0, 0);
        -o-transform: translate3d($value, 0, 0);
        -ms-transform: translate3d($value, 0, 0);
        transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value){
     -webkit-transform:  translate3d(0,$value, 0);
        -moz-transform: translate3d(0, $value, 0);
        -o-transform: translate3d(0, $value, 0);
        -ms-transform: translate3d(0, $value, 0);
        transform: translate3d(0, $value, 0);
}

@mixin perspective($value){
    -webkit-perspective: $value;
    -moz-perspective: $value;
    -o-perspective: $value;
    -ms-perspective: $value;
    perspective: $value;
}

@mixin transform-style($type){
    -webkit-transform-style: $type;
    -moz-transform-style: $type;
    -o-transform-style: $type;
    -ms-transform-style: $type;
    transform-style: $type;
}

@mixin backface-visibility($type){
    -webkit-backface-visibility: $type;
    -moz-backface-visibility: $type;
    -o-backface-visibility: $type;
    -ms-backface-visibility: $type;
        backface-visibility: $type;
}
